import { useRouter } from 'next/router';
import { NavigateFunction } from '../interfaces/navigate-function';
import { To } from '../interfaces/to';
import { useCallback } from 'react';
import { NavigateOptions } from '../interfaces/navigate-options';

export function useNextNavigate(): NavigateFunction {
  const { back, push } = useRouter();

  const navigate: NavigateFunction = useCallback(
    (toOrBack: To | -1, options?: NavigateOptions) => {
      if (toOrBack === -1) {
        back();
        return;
      }

      if (options?.preventScrollReset) {
        push(toOrBack, undefined, { scroll: false, shallow: true });
        return;
      }

      push(toOrBack);
    },
    [back, push],
  );

  return navigate;
}
