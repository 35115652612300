import { PropsWithChildren, useEffect, useReducer } from 'react';
import { CommonProviderProps } from './props';
import { CommonSettingsContext } from '@starsoft/common/context';
import { setSettings, settingsReducer } from '@starsoft/common/react-reducer';

export function CommonProvider({
  children,
  settings,
}: PropsWithChildren<CommonProviderProps>) {
  const [state, dispatch] = useReducer(settingsReducer, { settings });

  function onChangeSettings() {
    dispatch(setSettings(settings));
  }

  useEffect(onChangeSettings, [settings]);

  return (
    <CommonSettingsContext.Provider value={{ state, dispatch }}>
      {children}
    </CommonSettingsContext.Provider>
  );
}
